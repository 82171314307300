import { render, staticRenderFns } from "./Creeds.vue?vue&type=template&id=56f31321&"
import script from "./Creeds.vue?vue&type=script&lang=js&"
export * from "./Creeds.vue?vue&type=script&lang=js&"
import style0 from "./Creeds.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-creeds">
        <img src="@/assets/casestudy/creeds-logo.svg" alt="Creeds Coffee Bar">
        <span class="sep"></span>
        <p>We built our coffee bar with an eye trained on the environment that surrounds it – its history, its population, its geography. We believe that this space is a unique representation of a tribute to our community in Toronto.
          We will be serving espresso drinks, brewed coffee, cold brew, tea, gourmet sandwiches, salads and baked goods.</p>
      </div>
    </div>

    <div class="laptop-img creeds-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/creeds-main-img.png" alt="Creeds">
      </div>
    </div>

    <div class="container-about creeds-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/creeds-diamond.svg" alt="Creeds">
        <h3>About the project</h3>
        <p>This project was created for a very cool Coffee shop in Toronto. We hope that at least one of us ends up drinking a coffee there one day 😊 </p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>

          <div class="font-typo">
            <img class="doublefont" src="@/assets/casestudy/lato-font.svg" alt="Creeds Main Font">
            <img src="@/assets/casestudy/georgia-font.svg" alt="Creeds Second Font">
            <h3><span style="color:#343638">Co</span><span style="color:#EAEDF2">lo</span><span style="color:#556b71">rs</span></h3>

            <div class="colors">
              <div class="fcolor creeds-p">Primary</div>
              <div class="scolor creeds-s">Secondary</div>
              <div class="tcolor creeds-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>

            <div class="color-codes">
              <div class="ccodes">
                <p class="primary-cr">#556b71</p>
                <p class="secondary-cr">#c3c3c3</p>
                <p class="third-cr">#2d2d2d</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <testimonial tsTitle="#46585e">
        <div class="testimonial-text">
          <p class="testimonial-title-text">Frederick Lemire-Collu, CTO</p>
          <p class="testimonial-text__content">"The level of expertise and professionalism that Vali and Scriptics have demonstrated throughout the multitude of projects that we've collaborated on has been outstanding. From design to development, they have proven themselves time and time again".</p>
        </div>
      </testimonial>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Careful'}" class="left">
              <img src="@/assets/casestudy/btn-careful.jpg" alt="Careful">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Hoinarim'}" class="right">
              <img src="@/assets/casestudy/btn-hoinarim.jpg" alt="Wayspa">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs colorTitle="#46585e"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
    import Testimonial from "../../components/Testimonial";
    import ContactUs from "../../components/ContactUs";
    import Footer from "../../components/Home/Footer";
    import LetsChat from "../../components/LetsChat";
    export default {
        name: "Creeds",
      components: {LetsChat, Footer, ContactUs, Testimonial}
    }
</script>

<style lang="scss">
.logo-creeds img {
  max-width: 320px;
}
.creeds-laptop {
  background: #46585e;
}
.creeds-about {
  background: #c3c3c3;
}
.creeds-p {
  background: #556b71;
}
.primary-cr {
  color: #556b71;
}
.creeds-s {
  background: #c3c3c3;
}
.secondary-cr {
  color: #c3c3c3;
}
.creeds-t {
  background: #2d2d2d;
}
.third-cr {
  color: #2d2d2d;
}
.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #46585e 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>